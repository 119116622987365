import React, { useContext } from "react";
import PromoCHeck from "../assets/images/promo-check.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { GlobalContext, initialStateType, showToast } from "../globalContext";
import { useState } from "react";
import { useEffect } from "react";
import { getAllTaxes } from "../service/UserApi";
import { getAllDeposit } from "../service/UserApi";
import { verifyCoupon } from "../service/UserApi";
import reservationActions from "../actions/ReservationActions";
import WarningIcon from "../assets/images/carbon_warning.svg";
import CheckIcon from "../assets/images/lets-icons_check-fill.svg";
import {
  DATE_FORMAT,
  ONLY_DATE_FORMAT,
  getDatesBetween,
} from "../../utils/utils.ts";
import moment from "moment";
import Spinner from "../components/Spinner";
const PaymentDetailCard = () => {
  const [showReserveData, setShowReserveData] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState<string | undefined>(undefined);



  const { dispatch } = useContext(GlobalContext);

  const FIXED_SERVICE_CHARGES = 5.95;
  // const { state } = React.useContext(GlobalContext);

  // let { PaymentType } = state


  const { dispatch: gDispatch } = React.useContext(GlobalContext);

  const state: initialStateType = React.useContext(GlobalContext)?.state;
  const { reservation } = state;
  const [couponCode, setCouponCode] = useState("");
  const [couponName, setCouponName] = useState("");

  const navigate = useNavigate();
  const {
    airport,
    package: selectedPackage,
    dailyRate,
    days,
    checkin,
    checkout,
    taxes,
    deposit,

    coupon: discount,
    isResCompleted,
  } = reservation;
  console.log({ reservation });

  const calculateTotalCharges = (selectedPackage, dailyRate, days) => {
    let totalCharges = 0;

    const packageAmount = Number(dailyRate);
    if (days == 0) days = 1;
    totalCharges = packageAmount * days;
    console.log({ packageAmount, dailyRate, days, totalCharges });


    return totalCharges;
  };
  const totalCharges = calculateTotalCharges(selectedPackage, dailyRate, days);

  // const getData = async () => {
  //   const t_taxes = await getAllTaxes();
  //   const availableTaxes = t_taxes.filter((it) => it.is_active == "1");

  //   setTimeout(() => {
  //     // setTaxes(availableTaxes);
  //     setIsLoading(false);
  //   }, 200);
  // };



  const calculateTax = (subtotal, tax) => {
    let taxAmount = 0;

    if (tax.percentage == "0") {
      taxAmount += Number(tax.amount);
    } else {
      let percentAmount = subtotal * (Number(tax.percentage) / 100);
      taxAmount += percentAmount;
    }
    return taxAmount;
  };
  const calculateTotalTax = (subtotal, taxes) => {
    subtotal = Number(subtotal);
    let totalTaxAmount = 0;
    for (let i = 0; i < taxes?.length; i++) {
      const tax = taxes[i];
      totalTaxAmount += calculateTax(subtotal, tax);
    }
    return totalTaxAmount;
  };
  const calculateDiscount = (total, discount) => {
    let discountAmount = 0;
    total = Number(total);
    if (discount) {
      if (discount.percentage == "0") {
        discountAmount += Number(discount.amount);
      } else {
        let percentAmount = total * (Number(discount.percentage) / 100);
        discountAmount += percentAmount;
      }
    }
    return discountAmount;
  };
  const calculateDeposit = (total, deposit) => {
    let depositAmount = 0;
    let percentAmount = total * (Number(deposit) / 100);
    depositAmount += percentAmount;
    return depositAmount;
  };

  const calculatePayments = (subtotal, taxes, discount, deposit) => {
    subtotal = Number(subtotal);
    const discountAmount = calculateDiscount(subtotal, discount);
    const tax = calculateTotalTax(subtotal - discountAmount, taxes);

    const total = subtotal + tax - discountAmount + FIXED_SERVICE_CHARGES;
    const response = {
      subtotal: subtotal,
      tax: tax,
      discountAmount: discountAmount,
      total: total,
      depositAmount:
        calculateDeposit(total - FIXED_SERVICE_CHARGES, deposit) +
        FIXED_SERVICE_CHARGES,
    };
    return response;
  };
  const { subtotal, tax, discountAmount, total, depositAmount } =
    calculatePayments(totalCharges, taxes, discount, deposit);
  useEffect(() => {
    dispatch({
      type: "DEPOSIT_AMOUNT",
      payload: depositAmount,
    });
  }, [depositAmount]);
  useEffect(() => {
    dispatch(reservationActions.updateCoupon(""));
  }, []);

  useEffect(() => {
    dispatch(
      reservationActions.updatePaymentDetails({
        subtotal,
        tax,
        discountAmount,
        total,
        depositAmount,
      })
    );
  }, [subtotal, tax, discountAmount, total, depositAmount]);
  const Verify = async () => {
    try {
      setCouponLoading(true)

      const coupon = await verifyCoupon(airport?.id + '', couponCode);

      dispatch(reservationActions.updateCoupon(coupon));
      showToast(gDispatch, "Coupon Applied");

      setCouponLoading(false)
      setIsCouponApplied(true)
      gDispatch({
        type: "PAY_TYPE",
        payload: { PaymentType: "" },
      });
      setCouponError(undefined)
    } catch (error) {
      setInvalidCoupon(true);

      setCouponError('Coupon code is invalid')
      showToast(gDispatch, "Coupon code is invalid");
      // alert('invalid')
      setCouponLoading(false)
      setTimeout(() => {
        setInvalidCoupon(false);
      }, 2000);
      gDispatch({
        type: "PAY_TYPE",
        payload: { PaymentType: "" },
      });
    }
  };
  useEffect(() => {



    const isMobile = window.innerWidth < 700;
    setShowReserveData(isMobile ? false : true);
  }, []);

  return (
    <div className="block bg-[white] rounded-[10px] md:p-2 p-1">
      <div
        className={`payment-field `}
      >
        <input
          className={`${invalidCoupon ? " border border-red-500" : ""
            } `}
          onChange={(e) => {
            setCouponCode(e.target.value);
            setCouponName(e.target.value);
          }}
          readOnly={isCouponApplied}
          value={couponCode}
          type="text"
          placeholder=""
        />
        <label>Enter Promo Code</label>
        {!isCouponApplied && <button
          className={`${!couponName ? "disabled pointer-event-none opacity-5" : ""
            }`}
          type="button"
          onClick={() => {
            Verify();
          }}
        >
          {couponLoading ? <Spinner /> : <img src={PromoCHeck} alt="" />}
        </button>}
        {isCouponApplied && <button
          className={`${!couponName ? "disabled pointer-event-none opacity-5" : ""
            }`}
          type="button"
          onClick={() => {
            setCouponCode('')
            setCouponName('')
            setIsCouponApplied(false)
            dispatch(reservationActions.updateCoupon(undefined));
          }}
        >
          x
          {/* {couponLoading ? <Spinner /> : <img src={PromoCHeck} alt="" />} */}
        </button>}
        {couponError != undefined && <p className="text-[red] text-[12px]"> {couponError}</p>}
        {isCouponApplied && <p className="text-[green] font-[500] text-[12px]"> {couponCode} applied successfully</p>}
      </div>

      {/* <p className="clash text-center my-3 md:hidden flex items text-[#3C3A3F] text-[16px] font-[500] pb-3 justify-between border-b border-[#D6D6D6] reserve-title">
        Reservation Details
        <button
          className={`${!showReserveData ? "rotate-180" : ""}`}
          type="button"
          onClick={() => {
            setShowReserveData(!showReserveData);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_839_8192)">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(1 0 0 -1 0 20)"
                fill="#F2F2F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8828 6.61668C10.6484 6.38259 10.3307 6.25111 9.99943 6.25111C9.66818 6.25111 9.35047 6.38259 9.1161 6.61668L4.4011 11.33C4.16671 11.5645 4.03508 11.8825 4.03516 12.2141C4.03523 12.5456 4.16702 12.8635 4.40151 13.0979C4.63601 13.3323 4.95401 13.4639 5.28556 13.4639C5.61711 13.4638 5.93504 13.332 6.16943 13.0975L9.99943 9.26751L13.8294 13.0975C14.0651 13.3253 14.3808 13.4515 14.7085 13.4488C15.0363 13.4461 15.3498 13.3148 15.5817 13.0831C15.8136 12.8515 15.9452 12.538 15.9482 12.2103C15.9512 11.8825 15.8254 11.5667 15.5978 11.3308L10.8836 6.61584L10.8828 6.61668Z"
                fill="#2EABE3"
              />
            </g>
            <defs>
              <clipPath id="clip0_839_8192">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(1 0 0 -1 0 20)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </p> */}

     
        <>
          {isLoading ? (
            <div className="text-center w-fit my-4 mx-auto">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>

              <div className="mt-4">
                <div className="w-[100%]  rounded-[10px] p-[20px] mx-auto shadow-md">
                  <ul className="text-[12px] flex flex-col py-[10px] gap-[5px] border-b border-solid border-[#D6D6D6]">
                    <li>
                      Airport: <strong>{reservation.airport?.name}</strong>
                    </li>

                    <li>
                      Check-in: <strong>    {reservation.checkin}</strong>
                    </li>

                    <li>
                      Check-out: <strong>     {reservation.checkout}</strong>
                    </li>

                    <li >
                      <strong className="flex items-center gap-[5px]">
                        {reservation.days} Days of parking  <span className="tool-box-holder">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                              fill="#707070"
                            />
                          </svg>
                          <div className="tool-tip-box">
                            {getDatesBetween(checkin, checkout)?.map((date) => {
                              return (
                                <p>
                                  <strong>
                                    {moment(date).format(ONLY_DATE_FORMAT)}
                                  </strong>

                                </p>
                              );
                            })}
                            {/* <p>Our Daily Rates: ${dailyRate}</p>
                    <p>Package Price: ${subtotal - dailyRate}</p> */}
                          </div>
                        </span>
                      </strong>


                    </li>
                  </ul>

                  <ul className="text-[12px] flex flex-col py-[10px] gap-[5px] border-b border-solid border-[#D6D6D6]">
                    <li className="flex justify-between items-center">
                      <strong className="flex gap-[5px]">Subtotal     <span className="tool-box-holder">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                            fill="#707070"
                          />
                        </svg>
                        <div className="tool-tip-box">
                          {getDatesBetween(checkin, checkout)?.map((date) => {
                            return (
                              <p>
                                <strong>
                                  {moment(date).format(ONLY_DATE_FORMAT)}
                                </strong>
                                : ${Number(subtotal / days).toFixed(2)}
                              </p>
                            );
                          })}
                          {/* <p>Our Daily Rates: ${dailyRate}</p>
                    <p>Package Price: ${subtotal - dailyRate}</p> */}
                        </div>
                      </span></strong> <span>     ${totalCharges.toFixed(2)}</span>
                    </li>

                    <li className="flex justify-between items-center">
                      <span>Service Charge</span> <span>       ${FIXED_SERVICE_CHARGES.toFixed(2)}</span>
                    </li>

                    <li className="flex justify-between items-center">
                      <strong className="flex items-center gap-[5px]">
                        Taxes & Fees <span className="tool-box-holder">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                              fill="#707070"
                            />
                          </svg>
                          <div className="tool-tip-box">
                            <p>
                              <b>All Taxes</b>
                            </p>
                            {taxes.map((tax) => {
                              return (
                                <p key={tax.name}>
                                  {tax.name +
                                    ": $" +
                                    Number(calculateTax(subtotal, tax)).toFixed(2)}
                                  <br />
                                </p>
                              );
                            })}
                          </div>
                        </span>
                      </strong>
                      <span> +${tax.toFixed(2)}</span>
                    </li>






                    {discountAmount > 0 && (
                      <>
                        <li className="flex justify-between items-center">
                          <span className="flex gap-2">  Discount:{" "}
                            <span className="tool-box-holder">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                                  fill="#707070"
                                />
                              </svg>

                              <div className="tool-tip-box">
                                <p>
                                  You get{" "}
                                  {discount?.percentage != "0"
                                    ? discount?.percentage + "%"
                                    : "$" + discount?.amount}{" "}
                                  discount on this <strong>{couponName}</strong>{" "}
                                  Coupon code
                                </p>
                              </div>
                            </span></span> <span>            ${discountAmount.toFixed(2)}</span>
                        </li>
                      </>
                    )}
                  </ul>


                  <ul className="text-[12px] flex flex-col py-[10px] gap-[5px] border-b border-solid border-[#D6D6D6]">
                    <li className="flex items-center justify-between">
                      <strong>Total</strong> <strong>  ${total.toFixed(2)}</strong>
                    </li>
                  </ul>

                  <ul className="text-[12px] flex flex-col py-[20px] pb-0 ">
                    <li className="flex items-center justify-between font-black text-[red] md:text-[18px] text-[14px]">
                      <strong>Pay Now</strong> <strong> ${depositAmount.toFixed(2)}</strong>
                    </li>

                    <li className="flex items-center justify-between font-black md:text-[18px] text-[14px]">
                      <strong>Due at Parking Lot</strong> <strong> ${(total - depositAmount).toFixed(2)}</strong>
                    </li>
                  </ul>


                </div>

                <div className="w-[100%] rounded-[10px] md:block hidden mt-[20px] p-[20px] bg-white mx-auto shadow-md">
                  <ul className="text-[12px] flex flex-col py-[5px] gap-[5px]">
                    <li className="flex items-center">
                      <img src={CheckIcon} alt="icon" />
                      <p>Free Cancellation upto your start time</p>
                    </li>

                    <li className="flex items-center">
                      <img src={CheckIcon} alt="icon" />
                      <p>Secure Payment with SSL Encryption</p>
                    </li>

                    <li className="flex items-center">
                      <img src={CheckIcon} alt="icon" />
                      <p>Dedicated Customer Service Team to Help</p>
                    </li>
                  </ul>
                </div>
              </div>


              {/* <div className="block bg-[#F0F0F0] rounded-[10px] p-3 mb-3">
                <p className="text-[12px] text-[#626262] mb-3 pb-3 border-b border-[#D6D6D6]">
                  Reservation Summary
                </p>
                <div className="flex flex-wrap items-center grid-cols-2 gap-2 border-b border-[#D6D6D6] mb-3 pb-3 ">
                  <p className="w-[30%] text-[#3C3A3F] text-[14px] font-[500]">
                    Airport:
                  </p>
                  <p className="w-[65%] text-[#3C3A3F] text-[14px] text-right  ">
                    {reservation.airport?.name}
                  </p>
                  <p className="w-[30%] text-[#3C3A3F] text-[14px] font-[500]">
                    Check In:
                  </p>
                  <p className="w-[65%] text-[#3C3A3F] text-[14px] text-right  ">
                    {reservation.checkin}
                  </p>
                  <p className="w-[30%] text-[#3C3A3F] text-[14px] font-[500]">
                    Check Out:
                  </p>
                  <p className="w-[65%] text-[#3C3A3F] text-[14px] text-right  ">
                    {reservation.checkout}
                  </p>
                  <p className="w-[30%] text-[#3C3A3F] text-[14px] font-[500]">
                    Booking Days:
                  </p>
                  <p className="w-[65%] text-[#3C3A3F] text-[14px] text-right  ">
                    {reservation.days} day(s)
                  </p>
                </div>
              
              </div> */}

              {/* <div className="flex items-center justify-between">
                <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                  Subtotal:{" "}
                  <span className="tool-box-holder">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>
                    <div className="tool-tip-box">
                      {getDatesBetween(checkin, checkout)?.map((date) => {
                        return (
                          <p>
                            <strong>
                              {moment(date).format(ONLY_DATE_FORMAT)}
                            </strong>
                            : ${Number(subtotal / days).toFixed(2)}
                          </p>
                        );
                      })}
                     
                    </div>
                  </span>
                </p>
                <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                  ${totalCharges.toFixed(2)}
                </p>
              </div> */}

              {/* <div className="flex items-center justify-between mt-2">
                <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                  Lot Taxes & Fees{" "}
                  <span className="tool-box-holder">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>
                    <div className="tool-tip-box">
                      <p>
                        <b>All Taxes</b>
                      </p>
                      {taxes.map((tax) => {
                        return (
                          <p key={tax.name}>
                            {tax.name +
                              ": $" +
                              Number(calculateTax(subtotal, tax)).toFixed(2)}
                            <br />
                          </p>
                        );
                      })}
                    </div>
                  </span>
                </p>

                <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                  +${tax.toFixed(2)}
                </p>
              </div> */}
              {/* {discountAmount > 0 && (
                <>
                  <div className="flex items-center justify-between mt-2">
                    <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                      Discount:{" "}
                      <span className="tool-box-holder">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                            fill="#707070"
                          />
                        </svg>

                        <div className="tool-tip-box">
                          <p>
                            You get{" "}
                            {discount?.percentage != "0"
                              ? discount?.percentage + "%"
                              : "$" + discount?.amount}{" "}
                            discount on this <strong>{couponName}</strong>{" "}
                            Coupon code
                          </p>
                        </div>
                      </span>
                    </p>
                    <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                      ${discountAmount.toFixed(2)}
                    </p>
                  </div>
                </>
              )} */}
              {/* <div className="flex items-center justify-between mt-2  ">
                <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                  Service Charges:{" "}
                </p>
                <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                  ${FIXED_SERVICE_CHARGES.toFixed(2)}
                </p>
              </div> */}
              {/* <div className="flex items-center justify-between mt-2  ">
                <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                  Total:{" "}
                </p>
                <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                  ${total.toFixed(2)}
                </p>
              </div> */}
              {/* <div className="flex items-center justify-between mt-2 pb-3 mb-3 border-b border-[#D6D6D6]">
                <p className="text-[16px] flex gap-2 items-center text-[#3C3A3F]">
                  Pay at lot:
                </p>
                <p className="clash text-[16px] font-[500] text-[#3C3A3F]">
                  ${(total - depositAmount).toFixed(2)}
                </p>
              </div> */}
            </>
          )}
        </>
 

      {/* <div className="flex items-center justify-between mt-2">
        <p className="text-[20px] flex gap-2 items-center text-[#3C3A3F]">
          Due Now:{" "}
        </p>
        <p className="clash text-[21px] font-[500] text-[#2EABE3]">
          ${depositAmount.toFixed(2)}
      
        </p>
      </div> */}

      {/* <NavLink to='/reservation_Complete' className={`w-full book-area-btn mt-2 ${PaymentType ? 'opacity-100 pointer-events-auto block' : 'opacity-5 hidden pointer-events-none'} `}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="white" fill-opacity="0.25" />
                <path d="M5.625 9.25L9.2425 12.8675C9.27766 12.9026 9.32531 12.9223 9.375 12.9223C9.42469 12.9223 9.47234 12.9026 9.5075 12.8675L19.375 3" stroke="white" stroke-width="1.2" />
            </svg>
                Pay now</NavLink> */}
    </div>
  );
};

export default PaymentDetailCard;
