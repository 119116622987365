import React, { useState } from 'react'
import SearchReservations from '../user-edit-reservation/SearchReservations'
import { NavLink } from 'react-router-dom'


const SearchReservationPage = () => {
    return (
        <div className="container mx-auto fader">



            <div className="grid md:grid-cols-2 grid-cols-1 md:py-[80px] py-[40px] gap-10 fader">
                <div className="w-full">
                    <div className="flex items-center w-full">
                        {/* <NavLink
                            to={'/edit_reservation'}
                            className={`md:text-[16px] text-[14px] text-center text-[#707070] border-[transparent] border-l border-r border-t py-2 px-4 w-full`}>Edit Reservation</NavLink> */}
                        <NavLink
                            to={'/cancel'}

                            className={`md:text-[16px] text-[14px] text-center text-[#707070] border-[transparent] border-l border-r border-t py-2 px-4 w-full`}>Cancellation</NavLink>
                        <NavLink
                            to={'/search_reservation'}
                            className={`md:text-[16px] text-[14px] text-center text-[#2B2B2B] bg-[#F2F1F1] border-[#D9D9D9] border-l border-r border-t py-2 px-4 w-full `}>Search Reservation</NavLink>
                    </div>
                    <SearchReservations />
                </div>
                {/* 
                <div className="w-full flex flex-col gap-4">
                    <div className="w-full bg-[#EEEEEE] p-4">
                        <div className="flex items-center gap-1">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.375 6.53906C8.595 6.7524 8.125 7.35156 8.125 7.91656C8.125 8.48156 8.595 9.08073 9.375 9.29323V6.53906ZM10.625 10.7066V13.4599C11.405 13.2474 11.875 12.6482 11.875 12.0832C11.875 11.5182 11.405 10.9191 10.625 10.7066Z" fill="#2EABE3" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3327 10.0003C18.3327 14.6028 14.6018 18.3337 9.99935 18.3337C5.39685 18.3337 1.66602 14.6028 1.66602 10.0003C1.66602 5.39783 5.39685 1.66699 9.99935 1.66699C14.6018 1.66699 18.3327 5.39783 18.3327 10.0003ZM9.99935 4.37532C10.1651 4.37532 10.3241 4.44117 10.4413 4.55838C10.5585 4.67559 10.6243 4.83456 10.6243 5.00032V5.26449C11.9827 5.50782 13.1243 6.52866 13.1243 7.91699C13.1243 8.08275 13.0585 8.24172 12.9413 8.35893C12.8241 8.47614 12.6651 8.54199 12.4993 8.54199C12.3336 8.54199 12.1746 8.47614 12.0574 8.35893C11.9402 8.24172 11.8743 8.08275 11.8743 7.91699C11.8743 7.35199 11.4043 6.75282 10.6243 6.53949V9.43116C11.9827 9.67449 13.1243 10.6953 13.1243 12.0837C13.1243 13.472 11.9827 14.4928 10.6243 14.7362V15.0003C10.6243 15.1661 10.5585 15.3251 10.4413 15.4423C10.3241 15.5595 10.1651 15.6253 9.99935 15.6253C9.83359 15.6253 9.67462 15.5595 9.55741 15.4423C9.4402 15.3251 9.37435 15.1661 9.37435 15.0003V14.7362C8.01601 14.4928 6.87435 13.472 6.87435 12.0837C6.87435 11.9179 6.9402 11.7589 7.05741 11.6417C7.17462 11.5245 7.33359 11.4587 7.49935 11.4587C7.66511 11.4587 7.82408 11.5245 7.94129 11.6417C8.0585 11.7589 8.12435 11.9179 8.12435 12.0837C8.12435 12.6487 8.59435 13.2478 9.37435 13.4603V10.5695C8.01601 10.3262 6.87435 9.30532 6.87435 7.91699C6.87435 6.52866 8.01601 5.50782 9.37435 5.26449V5.00032C9.37435 4.83456 9.4402 4.67559 9.55741 4.55838C9.67462 4.44117 9.83359 4.37532 9.99935 4.37532Z" fill="#2EABE3" />
                            </svg>
                            <h5 className='text-[#2B2B2B] text-[16px]'>How changes affect total</h5>
                        </div>
                        <p className='text-[#2B2B2B] text-[12px] mt-4'>The amount due will be calculated at the rate above, based on the actual length of time your vehicle is parked. Because this is an exclusive rate, it cannot be combined with other coupons, offers, discounts, or promotions.</p>
                    </div>
                    <div className="w-full bg-[#EEEEEE] p-4">
                        <div className="flex items-center gap-1">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99935 1.45821C11.6887 1.45821 13.3402 1.95917 14.7448 2.89774C16.1495 3.83631 17.2443 5.17034 17.8908 6.73112C18.5373 8.29191 18.7065 10.0094 18.3769 11.6663C18.0473 13.3232 17.2338 14.8452 16.0392 16.0397C14.8446 17.2343 13.3227 18.0478 11.6657 18.3774C10.0088 18.707 8.29138 18.5378 6.73059 17.8913C5.16981 17.2448 3.83578 16.15 2.89721 14.7454C1.95864 13.3407 1.45768 11.6893 1.45768 9.99988C1.44993 8.876 1.66557 7.76179 2.09208 6.72197C2.51859 5.68214 3.14747 4.73743 3.94219 3.94272C4.73691 3.148 5.68161 2.51912 6.72144 2.09261C7.76126 1.6661 8.87547 1.45045 9.99935 1.45821ZM4.42435 14.6999L14.691 4.42488C13.293 3.2577 11.5087 2.65595 9.68935 2.73803C7.86997 2.82011 6.14717 3.58007 4.85989 4.8684C3.57261 6.15672 2.81405 7.88014 2.73345 9.69958C2.65284 11.519 3.25603 13.3028 4.42435 14.6999Z" fill="#2EABE3" />
                                <rect x="10" y="10" width="10" height="10" rx="5" fill="#EEEEEE" />
                                <path d="M12.5 17.5V16.3194L16.1667 12.6597C16.2222 12.6088 16.2836 12.5694 16.3508 12.5417C16.4181 12.5139 16.4886 12.5 16.5625 12.5C16.6366 12.5 16.7083 12.5139 16.7778 12.5417C16.8472 12.5694 16.9074 12.6111 16.9583 12.6667L17.3403 13.0556C17.3958 13.1065 17.4364 13.1667 17.4619 13.2361C17.4875 13.3056 17.5002 13.375 17.5 13.4444C17.5 13.5185 17.4873 13.5892 17.4619 13.6564C17.4366 13.7236 17.396 13.7849 17.3403 13.8403L13.6806 17.5H12.5ZM16.5556 13.8333L16.9444 13.4444L16.5556 13.0556L16.1667 13.4444L16.5556 13.8333Z" fill="#2EABE3" />
                            </svg>

                            <h5 className='text-[#2B2B2B] text-[16px]'>Cancellation/change</h5>
                        </div>
                        <p className='text-[#2B2B2B] text-[12px] mt-4'>
                            You may <b>cancel your reservation for a full refund</b> (including the service fee) within the <b>first 24 hours</b> after your reservation was originally submitted. Reservations canceled <b>after 24 hours will be not be refunded the service fee</b>.</p>
                        <p className='text-[#2B2B2B] text-[12px] mt-3 bg-[#F9F9F9] p-2'>We are unable to accept cancellations after the scheduled parking arrival date, nor can we make any changes to an existing reservation. Partial refunds are not issued for schedule changes or early returns.</p>
                        <p className='text-[#2B2B2B] text-[12px] mt-3'>

                            You can cancel your reservation directly from our<NavLink to="#" className="underline"> Help page</NavLink>, or <NavLink className='underline' to="/contact"> contact us</NavLink>

                        </p>
                    </div>
                </div> */}

            </div>

        </div>
    )
}

export default SearchReservationPage
