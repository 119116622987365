import React from "react";

const NotFoundPage = () => {
  return (
    <>
   
      <div className="w-full flex justify-center items-center text-7xl h-screen text-gray-700 ">
        Not Found
      </div>
    </>
  );
};

export default NotFoundPage;
