import React from 'react'
import AppStore from '../assets/images/app-store.svg'
import googlePlay from '../assets/images/google-play.svg'
import FPay1 from '../assets/images/f-pay1.svg'
import FPay2 from '../assets/images/f-pay2.svg'
import FPay3 from '../assets/images/f-pay3.svg'
import FPay4 from '../assets/images/f-pay4.svg'
import FPay5 from '../assets/images/f-pay5.svg'
import FPay6 from '../assets/images/f-pay6.svg'
import FPay7 from '../assets/images/f-pay7.svg'
import FPay8 from '../assets/images/f-pay8.svg'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className="container mx-auto">
                <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 md:grid-cols-4">
                    <div className="link-box">
                        <h4>Quick Links</h4>
                        <NavLink to='#'>Who are we?</NavLink>
                        <NavLink to='#'>How it works?</NavLink>
                        <NavLink to='#'>Our Parking Lots</NavLink>
                    </div>
                    <div className="link-box">
                        <h4>Collaborate with us</h4>
                        <NavLink to='#'>Parking providor</NavLink>
                        <NavLink to='#'>Affiliate</NavLink>

                    </div>
                    <div className="link-box">
                        <h4>Contact</h4>
                        <NavLink to='/contact'>Contact us</NavLink>
                        <NavLink to='/contact'>help</NavLink>
                    </div>
                    {/* <div className="link-box text-right">
                        <img className='block md:mb-6 mb-3 max-w-[160px] w-full ml-auto' src={AppStore} alt='' />
                        <img className='block max-w-[160px] w-full ml-auto' src={googlePlay} alt='' />
                    </div> */}
                </div>

                <div className="footer-pay-icons-holder">
                    <p>You can use these payment methods:</p>
                    <div className="flex items-center justify-between gap-4">
                        {/* <img src={FPay4} alt='' /> */}
                        <img src={FPay1} alt='' />
                        <img src={FPay2} alt='' />
                        <img src={FPay3} alt='' />

                        <img src={FPay5} alt='' />
                        <img src={FPay6} alt='' />
                        <img src={FPay7} alt='' />
                        <img src={FPay8} alt='' />
                    </div>
                </div>

                <div className="footer-bottom">
                    <ul>
                        <li>
                            <NavLink to='/terms_&_condition'>Terms of Use</NavLink>
                        </li>
                        <li>
                            <NavLink to='/privacy_&_policy'>Privacy Policy</NavLink>
                        </li>
                        <li>
                            <NavLink to='/cancel'>Cancellation Conditions</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to='#'>Cookie Policy</NavLink>
                        </li> */}
                    </ul>
                    <p>© Parking Maniac 2024 . All rights reserved.</p>
                </div>

            </div>
        </footer>
    )
}

export default Footer
