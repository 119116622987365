import React, { useState, useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en'; // Import the locale you need

type ListTimePickerProps = {
    defaultDate?: Dayjs;
    minTime?: Dayjs; // Add minTime prop
    onTimeSelect: (time: Dayjs) => void;
};

const ListTimePicker: React.FC<ListTimePickerProps> = ({ defaultDate, minTime, onTimeSelect }) => {
    const [selectedTime, setSelectedTime] = useState<string | null>(null);
    const timeRefs = useRef<(HTMLLIElement | null)[]>([]);

    // Generate time slots starting from minTime if provided, otherwise start of day
    const generateTimeSlots = () => {
        const times: string[] = [];
        let start = minTime ? minTime : dayjs().startOf('day');
        for (let i = 0; i < 48; i++) {
            times.push(start.add(i * 30, 'minute').format('hh:mm A'));
        }
        return times;
    };

    const timeSlots = generateTimeSlots();

    useEffect(() => {
        if (defaultDate) {
            const formattedDefaultDate = dayjs(defaultDate).format('hh:mm A');
            setSelectedTime(formattedDefaultDate);
            const index = timeSlots.indexOf(formattedDefaultDate);
            if (index !== -1 && timeRefs.current[index]) {
                timeRefs.current[index]?.scrollIntoView({ block: 'center' });
            }
        }
    }, [defaultDate, timeSlots]);

    const handleTimeSelect = (time: string) => {
        setSelectedTime(time);
        const selectedDayjs = minTime
            ? minTime.startOf('day').add(timeSlots.indexOf(time) * 30, 'minute')
            : dayjs().startOf('day').add(timeSlots.indexOf(time) * 30, 'minute');
        onTimeSelect(selectedDayjs);
    };

    return (
        <div>
            <ul className="time-slot-list">
                {timeSlots.map((time, index) => (
                    <li
                        key={index}
                        ref={(el) => (timeRefs.current[index] = el)}
                        className={`time-slot ${selectedTime === time ? 'selected' : ''}`}
                        onClick={() => handleTimeSelect(time)}
                    >
                        {time}
                    </li>
                ))}
            </ul>
            <style jsx>{`
                .time-slot-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    max-height: 330px;
                    white-space:nowrap;
                    overflow-y: auto;
                }
                .time-slot {
                    padding: 10px 15px;
                    cursor: pointer;
                }
                .time-slot:hover, .time-slot.selected {
                    background-color: #f0f0f0;
                }
            `}</style>
        </div>
    );
};

export default ListTimePicker;
