import React from 'react'
import ContactUs from '../contact-us-component/ContactUs'

const ContactPage = () => {
    return (
        <div className="py-20 bg-[#FAFAFA] ">
            <div className="container mx-auto">
                <h3 className='text-[#3C3A3F] text-[46px] font-[700]'>Contact Us</h3>
                {/* <p className='text-[#3C3A3F] text-[12px]'>Lorem ipsum dolor sit amet consectetur. Faucibus etiam tempus lorem condimentum nibh pretium hendrerit. Nec aliquam pulvinar risus purus eleifend aliquet.</p> */}

                <div className="w-full max-w-[500px] mt-10 ">
                    <ContactUs/>
                </div>
            </div>
        </div>
    )
}

export default ContactPage
